<app-slide>
  <app-title label="Presentation robot"></app-title>

  <app-two-column-layout>
    <app-list>
      <app-list-item [current]="step" [step]="1">
        <span class="emphasize">Fictional</span> robot used throughout presentation
      </app-list-item>
      <app-list-item [current]="step" [step]="2">Used to explain concepts</app-list-item>
      <app-list-item [current]="step" [step]="3">
        Task

        <app-list>
          <app-list-item [current]="step" [step]="4">Follow a (black) line</app-list-item>
          <app-list-item [current]="step" [step]="5">Stop when something is in front</app-list-item>
        </app-list>
      </app-list-item>

      <app-list-item [current]="step" [step]="6">
          Components
          <app-list>
            <app-list-item [current]="step" [step]="7">Line & distance sensor</app-list-item>
            <app-list-item [current]="step" [step]="8">Two motors</app-list-item>
          </app-list>
      </app-list-item>
    </app-list>
    <app-figure caption="Visual representation of robot scenario." [current]="step" [step]="3">
      <svg xmlns="http://www.w3.org/2000/svg" width="270" height="395" viewBox="0 0 270 395" style="overflow: visible;">
        <g fill="none" fill-rule="evenodd" transform="translate(0 6.683)">
          <path class="opacity-transition" [class.opacity-active]="step >= 4" stroke="#7F7F7F" stroke-width="10" d="M128,0.316718413 C156.005025,-4.66970567 193.673452,50.201924 220.674442,95.9149207 C247.675431,141.627917 265,150.05259 265,191.316718 C265,248.003741 198.737072,202.581024 170.606279,237.561141 C146.389717,267.673986 163.002686,382.316718 128,382.316718 C52.3369893,382.316718 59.1596399,296.473985 85.0987058,212.913366 C111.037772,129.352747 -101.173661,41.1221473 128,0.316718413 Z"/>
          <g transform="translate(0 64.317)" class="opacity-transition" [class.opacity-active]="step >= 5">
            <path fill="#939BA5" d="M58,3.51330623 C58,5.43898436 56.6570753,7 54.9998241,7 C53.3432179,7 52,5.43898436 52,3.51330623 C52,1.58768889 53.3432179,0.0266124591 54.9998241,0.0266124591 C55.2543347,0.0266124591 56.408429,-0.0369101788 56.6441738,0.0323264572 C57.9448756,0.414070157 58,1.88317553 58,3.51330623"/>
            <path fill="#A6AFBC" d="M60,3.5 C60,5.43302708 58.6570884,7 56.9998534,7 C55.3432048,7 54,5.43302708 54,3.5 C54,1.56703394 55.3432048,0 56.9998534,0 C58.6570884,0 60,1.56703394 60,3.5"/>
            <path fill="#EA3624" d="M59,3.49997117 C59,4.88075109 57.8803367,6 56.5003007,6 C55.1196633,6 54,4.88075109 54,3.49997117 C54,2.11930658 55.1196633,1 56.5003007,1 C57.8803367,1 59,2.11930658 59,3.49997117"/>
            <path fill="#E85F54" d="M56.9864699,1 C55.9864699,1 55.1568266,1.81896752 55,2.89218186 C55.3493235,2.56634326 55.797663,2.37031028 56.2865929,2.37031028 C57.398524,2.37031028 58.300123,3.38252922 58.300123,4.63124607 C58.300123,4.75687937 58.2908979,4.8799298 58.2730627,5 C58.7170972,4.58528428 59,3.96023047 59,3.26093579 C59,2.01228518 58.098401,1 56.9864699,1"/>
            <path fill="#8E1C17" d="M56.8528753,5.49439716 C55.4245706,5.49439716 54.2657456,4.34522981 54.2657456,2.92759973 C54.2657456,2.60039967 54.3286034,2.28782474 54.4406273,2 C54.1624347,2.40932573 54,2.90237584 54,3.43314336 C54,4.85077344 55.158825,6 56.5877521,6 C57.686831,6 58.6247199,5.31972467 59,4.3607431 C58.5351008,5.0446303 57.7471994,5.49439716 56.8528753,5.49439716"/>
            <path fill="#F4AFAA" d="M57.722189,3 C57.7215518,3 57.7209146,3 57.7196401,2.99993099 C57.5832751,2.99827473 57.4864177,2.85866602 57.417598,2.73051309 C57.3048101,2.52044443 57.2054039,2.30064525 57.0703133,2.10679411 C56.9071851,1.87236465 56.703912,1.71412305 56.4381914,1.65263448 C56.2718771,1.61440254 55.9570906,1.58024223 56.0048821,1.31744936 C56.0380175,1.13850454 56.2533978,1.01628653 56.405056,1.00262241 C56.4248098,1.00082813 56.4445636,1 56.4643175,1 C56.8957151,1 57.3685321,1.41019979 57.6342527,1.72985749 C57.8426235,1.97967634 58.0204078,2.29346813 57.9981051,2.62899831 C57.9866351,2.80352645 57.8834056,3 57.722189,3"/>
            <path fill="#939BA5" d="M13,3.51330623 C13,5.43898436 11.656867,7 10,7 C8.34313304,7 7,5.43898436 7,3.51330623 C7,1.58768889 8.34313304,0.0266124591 10,0.0266124591 C10.2543297,0.0266124591 11.4083428,-0.0369101788 11.644083,0.0323264572 C12.9445834,0.414070157 13,1.88317553 13,3.51330623"/>
            <path fill="#A6AFBC" d="M15,3.5 C15,5.43302708 13.6568214,7 11.9999707,7 C10.3431786,7 9,5.43302708 9,3.5 C9,1.56703394 10.3431786,0 11.9999707,0 C13.6568214,0 15,1.56703394 15,3.5"/>
            <polyline fill="#C1ADA3" points="5.981 12 25 12 23.866 23 4 23 5.981 12"/>
            <polyline fill="#E0C9BA" points="6 12 5.064 12.284 3 23 3.896 23 6 12"/>
            <polyline fill="#C1ADA3" points="2.981 32 22 32 20.866 43 1 43 2.981 32"/>
            <polyline fill="#A6AFBC" points="3 50 7.359 50 13 10 8.499 10 3 50"/>
            <polyline fill="#A6AFBC" points="9 6 15.844 50 21 50 13.236 6 9 6"/>
            <polyline fill="#939BA5" points="9.126 6 8 6 14.049 50 16 50 9.126 6"/>
            <polyline fill="#939BA5" points="8.093 6 2 50 3.522 50 9 8.018 8.093 6"/>
            <polyline fill="#A6AFBC" points="48 50 52.359 50 58 10 53.499 10 48 50"/>
            <polyline fill="#A6AFBC" points="54 6 60.844 50 66 50 58.236 6 54 6"/>
            <polyline fill="#939BA5" points="54.126 6 53 6 59.049 50 61 50 54.126 6"/>
            <polyline fill="#939BA5" points="53.093 6 47 50 48.523 50 54 8.018 53.093 6"/>
            <polyline fill="#E0C9BA" points="64.507 23 9.999 23 8 11.823 8.493 11 62.507 11.823 65 22.177 64.507 23"/>
            <polyline fill="#FFE5D2" points="64 22 9.981 22 8 11 62.019 11 64 22"/>
            <polyline fill="#EA3624" points="17.355 22 10 22 15.645 11 23 11 17.355 22"/>
            <polyline fill="#EA3624" points="30.355 22 23 22 28.645 11 36 11 30.355 22"/>
            <polyline fill="#EA3624" points="43.355 22 36 22 41.645 11 49 11 43.355 22"/>
            <polyline fill="#EA3624" points="56.355 22 49 22 54.644 11 62 11 56.355 22"/>
            <polyline fill="#E0C9BA" points="66.507 43 11.999 43 10 31.823 10.493 31 64.508 31.823 67 42.177 66.507 43"/>
            <polyline fill="#FFE5D2" points="67 42 12.981 42 11 31 65.019 31 67 42"/>
            <polyline fill="#EA3624" points="20.355 42 13 42 18.645 31 26 31 20.355 42"/>
            <polyline fill="#EA3624" points="33.355 42 26 42 31.645 31 39 31 33.355 42"/>
            <polyline fill="#EA3624" points="46.355 42 39 42 44.645 31 52 31 46.355 42"/>
            <polyline fill="#EA3624" points="59.355 42 52 42 57.645 31 65 31 59.355 42"/>
            <polyline fill="#E0C9BA" points="3 32 2.064 32.284 0 43 .896 43 3 32"/>
            <path fill="#EA3624" d="M14,3.49997117 C14,4.88069342 12.8806904,6 11.5000301,6 C10.1193096,6 9,4.88069342 9,3.49997117 C9,2.11930658 10.1193096,1 11.5000301,1 C12.8806904,1 14,2.11930658 14,3.49997117"/>
            <path fill="#E85F54" d="M11.9864694,1 C10.9863157,1 10.1568929,1.81896752 10,2.89218186 C10.3489037,2.56640948 10.7973185,2.37031028 11.2865709,2.37031028 C12.3986592,2.37031028 13.3001015,3.38252922 13.3001015,4.63124607 C13.3001015,4.75687937 13.2905686,4.8799298 13.2729789,5 C13.71715,4.58528428 14,3.96023047 14,3.26093579 C14,2.01228518 13.0984963,1 11.9864694,1"/>
            <path fill="#8E1C17" d="M11.8531506,5.49439716 C10.4241168,5.49439716 9.26570319,4.34522981 9.26570319,2.92759973 C9.26570319,2.60039967 9.32806782,2.28782474 9.440598,2 C9.16238454,2.40932573 9,2.90237584 9,3.43314336 C9,4.85077344 10.1584136,6 11.5874474,6 C12.6866084,6 13.6250654,5.31972467 14,4.3607431 C13.5351906,5.0446303 12.7471681,5.49439716 11.8531506,5.49439716"/>
            <path fill="#F4AFAA" d="M12.7224764,3 C12.7216479,3 12.7207556,3 12.7199271,3 C12.5830959,2.99827471 12.4863517,2.85866601 12.4176493,2.73058209 C12.3049723,2.52044442 12.2057426,2.30064524 12.0705684,2.1067941 C11.9070975,1.87236464 11.7041769,1.71412304 11.437971,1.65270349 C11.2720146,1.61440253 10.9569268,1.58024222 11.0049165,1.31744936 C11.0376107,1.13850454 11.2531501,1.01628653 11.4052131,1.00262241 C11.4247149,1.00082813 11.4442804,1 11.4640372,1 C11.8956896,1 12.368385,1.41019978 12.6345908,1.72985749 C12.84261,1.97967633 13.0203567,2.29346812 12.9981144,2.62899829 C12.9865791,2.80352643 12.883398,3.00006899 12.7224764,3"/>
            <polyline fill="#7E858C" points="13 43 19.882 43 20 43.634 15.151 43.827 13.307 45 13 43"/>
            <polyline fill="#7E858C" points="10 23 15.886 23 16 23.661 11.648 23.868 10.283 25 10 23"/>
            <polyline fill="#7E858C" points="56 23 61.886 23 62 23.661 57.648 23.868 56.283 25 56 23"/>
            <polyline fill="#7E858C" points="58 43 64.881 43 65 43.64 60.153 43.841 58.306 45 58 43"/>
          </g>
          <g transform="translate(101.702 114.512)">
            <g class="prepare-corner-animation" [class.corner-animation]="step >= 9">
            <path fill="#18120E" d="M33.6,132.462 C33.6,143.491 39.859,151.848 54.658,152.433 C89.03,153.791 75.716,143.491 75.716,132.462 C75.716,121.432 66.27,113.13 54.658,112.491 C39.849,111.675 33.6,121.432 33.6,132.462"/>
            <path fill="#4C5355" d="M45.012,132.462 C45.012,143.491 54.866,152.433 67.021,152.433 C79.176,152.433 89.03,143.491 89.03,132.462 C89.03,121.432 79.176,112.491 67.021,112.491 C54.866,112.491 45.012,121.432 45.012,132.462"/>
            <path fill="#18120E" d="M55.337,132.326 C55.337,138.862 61.176,144.161 68.38,144.161 C75.583,144.161 81.422,138.862 81.422,132.326 C81.422,125.79 75.583,120.491 68.38,120.491 C61.176,120.491 55.337,125.79 55.337,132.326"/>
            <path fill="#344046" d="M63.489,132.411 C63.489,134.999 65.8,137.096 68.651,137.096 C71.502,137.096 73.814,134.999 73.814,132.411 C73.814,129.824 71.502,127.727 68.651,127.727 C65.8,127.727 63.489,129.824 63.489,132.411"/>
            <path fill="#6FB744" d="M28.673,28.938 C28.673,44.92 44.191,57.876 63.335,57.876 C82.478,57.876 97.997,44.92 97.997,28.938 C97.997,12.956 82.478,-1.42108547e-14 63.335,-1.42108547e-14 C44.191,-1.42108547e-14 28.673,12.956 28.673,28.938"/>
            <path fill="#4A5F70" d="M97.997,28.938 C97.997,44.923 82.476,57.876 63.337,57.876 C61.674,57.876 60.043,57.778 58.446,57.582 C41.613,55.61 28.677,43.537 28.677,28.938 C28.677,14.339 41.613,2.267 58.446,0.294 C60.043,0.098 61.674,-1.42108547e-14 63.337,-1.42108547e-14 C82.476,-1.42108547e-14 97.997,12.953 97.997,28.938"/>
            <path fill="#8E9EAA" d="M88.215,28.938 C88.215,43.537 75.27,55.61 58.446,57.582 C41.613,55.61 28.677,43.537 28.677,28.938 C28.677,14.339 41.613,2.267 58.446,0.294 C75.27,2.267 88.215,14.339 88.215,28.938"/>
            <path fill="#18120E" d="M24.606,69.448 C24.84,70.456 24.975,71.502 24.975,72.58 C24.975,80.244 18.74,86.479 11.076,86.479 L11.076,94.061 C22.921,94.061 32.557,84.425 32.557,72.58 C32.557,71.516 32.476,70.471 32.326,69.448 L24.606,69.448"/>
            <path fill="#18120E" d="M13.291 90.061C13.291 97.39 7.329 103.353-3.73034936e-13 103.353L-3.73034936e-13 98.662C4.742 98.662 8.6 94.803 8.6 90.061 8.6 85.319 4.742 81.461-3.73034936e-13 81.461L-3.73034936e-13 76.77C7.329 76.77 13.291 82.732 13.291 90.061M18.829 70.124C18.829 76.038 23.623 80.831 29.536 80.831 35.449 80.831 40.243 76.038 40.243 70.124 40.243 64.211 35.449 59.418 29.536 59.418 23.623 59.418 18.829 64.211 18.829 70.124"/>
            <path fill="#EB8D2D" d="M32.236,106.264 C32.899,106.264 35.53,105.008 34.095,105.008 C33.432,105.008 30.8,106.264 32.236,106.264"/>
            <path fill="#4A5F70" d="M89.479,111.182 L67.13,111.182 C61.81,111.182 56.702,106.781 55.779,101.401 L50.268,69.276 C49.345,63.896 52.943,59.494 58.263,59.494 L80.612,59.494 C85.932,59.494 91.04,63.896 91.963,69.276 L97.474,101.401 C98.397,106.781 94.799,111.182 89.479,111.182"/>
            <path fill="#8E9EAA" d="M62.635,111.182 L31.473,111.182 C28.577,111.182 27.121,107.252 28.238,102.448 L36.025,68.967 C37.142,64.163 40.427,60.233 43.323,60.233 L74.485,60.233 C77.381,60.233 78.837,64.163 77.72,68.967 L69.933,102.448 C68.815,107.252 65.531,111.182 62.635,111.182"/>
            <path fill="#18120E" d="M59.129,101.933 L39.281,101.933 C37.436,101.933 36.509,99.43 37.221,96.37 L42.181,75.045 C42.892,71.985 44.984,69.482 46.829,69.482 L66.676,69.482 C68.521,69.482 69.449,71.985 68.737,75.045 L63.777,96.37 C63.066,99.43 60.974,101.933 59.129,101.933"/>
            <path fill="#EB8D2D" d="M41.192 97.654L39.363 97.654C39.193 97.654 39.585 95.858 40.234 93.664L44.757 78.372C45.406 76.178 46.076 74.382 46.246 74.382L48.075 74.382C48.245 74.382 47.853 76.178 47.204 78.372L42.681 93.664C42.032 95.858 41.362 97.654 41.192 97.654M46.898 97.654L45.069 97.654C44.899 97.654 45.291 95.858 45.94 93.664L50.463 78.372C51.112 76.178 51.782 74.382 51.952 74.382L53.781 74.382C53.951 74.382 53.559 76.178 52.91 78.372L48.387 93.664C47.738 95.858 47.068 97.654 46.898 97.654M52.604 97.654L50.775 97.654C50.605 97.654 50.997 95.858 51.646 93.664L56.169 78.372C56.818 76.178 57.488 74.382 57.658 74.382L59.487 74.382C59.657 74.382 59.265 76.178 58.616 78.372L54.093 93.664C53.444 95.858 52.774 97.654 52.604 97.654M58.31 97.654L56.481 97.654C56.311 97.654 56.703 95.858 57.352 93.664L61.875 78.372C62.524 76.178 63.194 74.382 63.364 74.382L65.193 74.382C65.363 74.382 64.971 76.178 64.322 78.372L59.799 93.664C59.15 95.858 58.48 97.654 58.31 97.654"/>
            <path fill="#18120E" d="M88.073 60.233L39.223 60.233C37.396 60.233 35.901 58.737 35.901 56.91 35.901 55.082 37.396 53.587 39.223 53.587L88.073 53.587C89.901 53.587 91.396 55.082 91.396 56.91 91.396 58.737 89.901 60.233 88.073 60.233M93.859 116.275L29.586 116.275C27.759 116.275 26.264 114.779 26.264 112.952 26.264 111.124 27.759 109.629 29.586 109.629L93.859 109.629C95.686 109.629 97.181 111.124 97.181 112.952 97.181 114.779 95.686 116.275 93.859 116.275M67.143 41.034L44.089 41.034C36.372 41.034 30.059 34.721 30.059 27.005 30.059 19.288 36.372 12.975 44.089 12.975L67.143 12.975C74.86 12.975 81.173 19.288 81.173 27.005 81.173 34.721 74.86 41.034 67.143 41.034"/>
            <polygon fill="#FAFAF6" points="38.385 23.938 41.104 23.938 41.104 21.189 38.385 21.189"/>
            <polygon fill="#FAFAF6" points="43.698 23.938 46.417 23.938 46.417 21.189 43.698 21.189"/>
            <polygon fill="#FAFAF6" points="41.041 21.251 43.76 21.251 43.76 18.502 41.041 18.502"/>
            <polygon fill="#FAFAF6" points="41.041 26.624 43.76 26.624 43.76 23.875 41.041 23.875"/>
            <polygon fill="#FAFAF6" points="45.768 33.825 48.487 33.825 48.487 31.076 45.768 31.076"/>
            <polygon fill="#FAFAF6" points="48.415 36.502 51.134 36.502 51.134 33.752 48.415 33.752"/>
            <polygon fill="#FAFAF6" points="53.527 36.502 56.246 36.502 56.246 33.752 53.527 33.752"/>
            <polygon fill="#FAFAF6" points="58.638 36.502 61.357 36.502 61.357 33.752 58.638 33.752"/>
            <polygon fill="#FAFAF6" points="61.376 33.733 64.095 33.733 64.095 30.983 61.376 30.983"/>
            <polygon fill="#FAFAF6" points="64.977 23.938 67.696 23.938 67.696 21.189 64.977 21.189"/>
            <polygon fill="#FAFAF6" points="70.291 23.938 73.009 23.938 73.009 21.189 70.291 21.189"/>
            <polygon fill="#FAFAF6" points="67.634 21.251 70.353 21.251 70.353 18.502 67.634 18.502"/>
            <polygon fill="#FAFAF6" points="67.634 26.624 70.353 26.624 70.353 23.875 67.634 23.875"/>
            <path fill="#18120E" d="M95.19,69.448 C95.424,70.456 95.56,71.502 95.56,72.58 C95.56,80.244 89.324,86.479 81.66,86.479 L81.66,94.061 C93.505,94.061 103.141,84.425 103.141,72.58 C103.141,71.516 103.061,70.471 102.91,69.448 L95.19,69.448"/>
            <path fill="#18120E" d="M83.876 90.061C83.876 97.39 77.913 103.353 70.584 103.353L70.584 98.662C75.327 98.662 79.185 94.803 79.185 90.061 79.185 85.319 75.327 81.461 70.584 81.461L70.584 76.77C77.913 76.77 83.876 82.732 83.876 90.061M89.414 70.124C89.414 76.038 94.207 80.831 100.12 80.831 106.034 80.831 110.827 76.038 110.827 70.124 110.827 64.211 106.034 59.418 100.12 59.418 94.207 59.418 89.414 64.211 89.414 70.124"/>
            </g>
          </g>
        </g>
      </svg>
    </app-figure>
  </app-two-column-layout>
</app-slide>
