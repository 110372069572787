<div class="overview">
  <app-section-overview-item [active]="current === 1">Introduction</app-section-overview-item>
  <app-section-overview-item [active]="current === 2">Preliminaries</app-section-overview-item>
  <app-section-overview-item [active]="current === 3">Overview</app-section-overview-item>
  <app-section-overview-item [active]="current === 4">Concepts</app-section-overview-item>
  <app-section-overview-item [active]="current === 5">Tools</app-section-overview-item>
  <app-section-overview-item [active]="current === 6">Supervisory Layer</app-section-overview-item>
  <app-section-overview-item [active]="current === 7">Evaluation</app-section-overview-item>
  <app-section-overview-item [active]="current === 8">Conclusion</app-section-overview-item>
</div>
