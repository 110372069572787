<app-slide>
  <app-title label="Debugging"></app-title>

  <app-two-column-layout>
    <app-list>
      <app-list-item [current]="step" [step]="1">View <span class="emphasize">states</span> and <span class="emphasize">variables</span> of components</app-list-item>
      <app-list-item [current]="step" [step]="2">Publish state to ROS-topic</app-list-item>
      <app-list-item [current]="step" [step]="3">Developed a <span class="emphasize">tool</span> that listens to this topic</app-list-item>
      <app-list-item [current]="step" [step]="4">Allow <span class="emphasize">easier</span> understanding of behaviour</app-list-item>
    </app-list>
    <app-figure caption="Visual debugging of controller execution." [current]="step" [step]="3">
      <img class="image" src="/assets/debugging.png" width="90%">
    </app-figure>
  </app-two-column-layout>
</app-slide>
