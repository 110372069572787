<app-slide>
  <app-title label="Limitations"></app-title>

  <app-two-column-layout>
    <app-list>
      <app-list-item [current]="step" [step]="1"><span class="emphasize">Unique</span> message topic names required</app-list-item>
      <app-list-item [current]="step" [step]="2">Actions can <span class="emphasize">not</span> be remapped</app-list-item>
      <app-list-item [current]="step" [step]="3">Movement can not be <span class="emphasize">stopped</span> directly if <span class="emphasize">blocked</span></app-list-item>
      <app-list-item [current]="step" [step]="4">Generator only for <span class="emphasize">ROS2</span></app-list-item>
    </app-list>
  </app-two-column-layout>
</app-slide>
