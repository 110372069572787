<app-slide>
  <app-title label="Model-Driven Engineering"></app-title>

  <app-two-column-layout>
    <app-list>
      <app-list-item [current]="step" [step]="1">
        Software engineering methodology
      </app-list-item>
      <app-list-item [current]="step" [step]="2">Increase <span class="emphasize">compatibility</span> between systems</app-list-item>
      <app-list-item [current]="step" [step]="3"><span class="emphasize">Simplify</span> design process</app-list-item>
      <app-list-item [current]="step" [step]="4">
        Incorporate <span class="emphasize">domain</span> into software development
        <app-list>
          <app-list-item [current]="step" [step]="5">Promote <span class="emphasize">communications</span> between stakeholders</app-list-item>
        </app-list>
      </app-list-item>
      <app-list-item [current]="step" [step]="6">
        Construct model using domain-specific languages (<span class="emphasize">DSLs</span>)
      </app-list-item>
      <app-list-item [current]="step" [step]="7">
        Transformations
        <app-list>
          <app-list-item [current]="step" [step]="8">Model to model</app-list-item>
          <app-list-item [current]="step" [step]="9">Model to text</app-list-item>
        </app-list>
      </app-list-item>
    </app-list>
  </app-two-column-layout>
</app-slide>
