<div class="slide">
  <div class="logo" inlineSVG="/assets/logo.svg"></div>

  <div class="title">
    RoboSC: a domain-specific language for supervisory<br>
    controller synthesis of ROS-based applications
  </div>

  <div class="thesis">
    Bart Wesselink, Koen de Vos, Ivan Kurtev, Michel Reniers, Elena Torta
  </div>

  <div class="exam">
    Eindhoven University of Technology
  </div>
</div>
